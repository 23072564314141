// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-blog-1821-test-post-js": () => import("./../../../src/pages/blog/1821-test-post.js" /* webpackChunkName: "component---src-pages-blog-1821-test-post-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-childpage-child-page-test-js": () => import("./../../../src/pages/childpage/child-page-test.js" /* webpackChunkName: "component---src-pages-childpage-child-page-test-js" */),
  "component---src-pages-content-block-revamp-js": () => import("./../../../src/pages/content-block-revamp.js" /* webpackChunkName: "component---src-pages-content-block-revamp-js" */),
  "component---src-pages-corporate-partnerships-test-draft-js": () => import("./../../../src/pages/corporate-partnerships-test-draft.js" /* webpackChunkName: "component---src-pages-corporate-partnerships-test-draft-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-fa-qstest-js": () => import("./../../../src/pages/FAQstest.js" /* webpackChunkName: "component---src-pages-fa-qstest-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-parentpage-parent-page-test-js": () => import("./../../../src/pages/parentpage/parent-page-test.js" /* webpackChunkName: "component---src-pages-parentpage-parent-page-test-js" */),
  "component---src-pages-participating-js": () => import("./../../../src/pages/participating.js" /* webpackChunkName: "component---src-pages-participating-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-teach-js": () => import("./../../../src/pages/teach.js" /* webpackChunkName: "component---src-pages-teach-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-teacher-sign-up-js": () => import("./../../../src/pages/thank-you-teacher-sign-up.js" /* webpackChunkName: "component---src-pages-thank-you-teacher-sign-up-js" */),
  "component---src-pages-thanks-teacher-js": () => import("./../../../src/pages/thanks-teacher.js" /* webpackChunkName: "component---src-pages-thanks-teacher-js" */),
  "component---src-pages-ue-2021-online-js": () => import("./../../../src/pages/UE2021Online.js" /* webpackChunkName: "component---src-pages-ue-2021-online-js" */),
  "component---src-pages-university-partners-js": () => import("./../../../src/pages/university-partners.js" /* webpackChunkName: "component---src-pages-university-partners-js" */),
  "component---src-pages-volunteer-apply-js": () => import("./../../../src/pages/volunteer-apply.js" /* webpackChunkName: "component---src-pages-volunteer-apply-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

